import { Element, useEditor } from '@craftjs/core';
import { Layers } from '@craftjs/layers';
import * as Accordion from '@radix-ui/react-accordion';
import { useAtom } from 'jotai';
import { atom } from 'jotai';
import { decode, encode } from 'js-base64';
import {
  CloudDownloadIcon,
  PanelRightCloseIcon,
  PanelRightOpenIcon,
  RocketIcon,
  TrashIcon,
} from 'lucide-react';
import React, { useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { isDeepEqual } from 'remeda';

import { AccordionSection } from './components/AccordionSection';
import { Button } from './components/Button';
import { Container } from './components/Container';
import { HeroSection } from './components/HeroSection';
import { Image } from './components/Image';
import { LeonieHero } from './components/LeonieHero';
import { ListingsSection } from './components/ListingsSection';
import { ShowcaseCarousel } from './components/ShowcaseCarousel';
import { ShowcaseCarouselItem } from './components/ShowcaseCarouselItem';
import { Spacer } from './components/Spacer';
import { Text } from './components/Text';
import TimelineSection, { DEFAULT_TIMELINE_ITEMS } from './components/TimelineSection';
import {
  EditablePageData,
  useCreateEditablePageVersion,
  useDeleteVersion,
  useEditablePagesQueryParams,
  useGetEditablePageVersions,
  useSetActiveVersion,
  useUpdateEditablePageVersion,
} from './EditablePages.hooks';
import { Layer } from './Layer';
import BlackSectionWrapper from './wrappers/BlackSectionWrapper';
import CarouselSectionWrapper from './wrappers/CarouselSectionWrapper';
import FeaturedSectionWrapper from './wrappers/FeaturedSectionWrapper';
import { FollowingSectionWrapper } from './wrappers/FollowingSectionWrapper';
import FooterSectionWrapper from './wrappers/FooterSectionWrapper';
import IconsSectionWrapper from './wrappers/IconsSectionWrapper';
import InstagramSectionWrapper from './wrappers/InstagramSectionWrapper';
import ListingSectionWrapper from './wrappers/ListingSectionWrapper';
import LookingForSectionWrapper from './wrappers/LookingForSectionWrapper';
import { MatrixSectionWrapper } from './wrappers/MatrixSectionWrapper';
import SampleSaleSectionWrapper from './wrappers/SampleSaleSectionWrapper';
import TrendingNowSectionWrapper from './wrappers/TrendingNowSectionWrapper';
import { IconChevronDown } from 'assets/icons';
import { Button as SecondaryButton, NamedLink, PrimaryButton } from 'components';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/_chadcn/Dialog';
import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';
import { cn } from 'util/cn';

const enableLinksAtom = atom(false);
export const useEnableLinksState = () => useAtom(enableLinksAtom);

export const Toolbox = ({ page }: { page: EditablePageData }) => {
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [enableLinks, setEnableLinks] = useEnableLinksState();
  const [showGuidelines, setShowGuidelines] = React.useState(true);

  const createNewVersion = useCreateEditablePageVersion();
  const editablePageVersions = useGetEditablePageVersions();
  const updateVersion = useUpdateEditablePageVersion();
  const [{ version }, setQueryParams] = useEditablePagesQueryParams();
  const path = page.path;
  const pageVersionData = editablePageVersions?.data?.find(v => v.path === path);
  const setActiveVersion = useSetActiveVersion();
  const deleteVersion = useDeleteVersion();

  const { connectors, actions, selected, query } = useEditor(state => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes?.[currentNodeId]?.related?.settings,
      };
    }

    return { selected };
  });

  useReloadOnEditorUpdate();

  useEffect(() => {
    actions.setOptions(options => {
      (options as any).showGuidelines = showGuidelines;
    });
  }, [showGuidelines, actions]);

  const pageContentRef = useRef('');
  useEffect(() => {
    if (pageContentRef.current !== page?.content) {
      pageContentRef.current = page?.content;
      if (pageContentRef.current) {
        actions.deserialize(decode(pageContentRef.current));
      }
    }
  }, [actions, page, page?.content]);

  const isProdVersion = !version || version === pageVersionData?.activeVersion;
  const hasContentChanged = !isDeepEqual(
    JSON.parse(query.serialize() || '{}'),
    JSON.parse(decode(page?.content) || '{}')
  );

  return (
    <div className={cn(`relative bg-neutral-100`, isExpanded ? 'min-w-[350px]' : 'w-0')}>
      <button
        className="absolute -left-6 top-1/2 -translate-y-1/2 text-white bg-black p-1 border-2 border-white rounded-l-md shadow-md"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? <PanelRightCloseIcon /> : <PanelRightOpenIcon />}
      </button>
      {isExpanded && (
        <div className="overflow-auto max-h-screen grid gap-2 content-start px-2 py-1">
          <NamedLink name="EditablePages" params={{ pageId: 'list' }}>
            <SecondaryButton className="w-full">Back to list</SecondaryButton>
          </NamedLink>
          <Dialog>
            <DialogTrigger asChild>
              <PrimaryButton inProgress={createNewVersion.isLoading} disabled={!hasContentChanged}>
                Save Page
              </PrimaryButton>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Save changes</DialogTitle>
                <DialogDescription className="mt-2">
                  Add a description to help identify this version later
                </DialogDescription>
              </DialogHeader>
              <div className="py-4">
                <label className="block text-sm font-medium mb-1">Description</label>
                <input
                  id="versionDescription"
                  className="w-full p-2 border border-gray-300 rounded"
                  placeholder="e.g., Updated hero image, fixed footer links"
                />
              </div>
              <DialogFooter>
                <DialogClose asChild>
                  <button className="bg-neutral-200 px-2 py-1 rounded-md">Cancel</button>
                </DialogClose>
                <DialogClose asChild>
                  <button
                    className="bg-orange-300 px-2 py-1 rounded-md"
                    disabled={updateVersion.isLoading}
                    onClick={() => {
                      const description = (
                        document.getElementById('versionDescription') as HTMLInputElement
                      )?.value;
                      updateVersion.mutateAsync({
                        path,
                        version: String(version),
                        content: encode(query.serialize()),
                        description,
                      });
                    }}
                  >
                    Overwrite Current
                  </button>
                </DialogClose>
                <DialogClose asChild>
                  <button
                    className="bg-green-300 px-2 py-1 rounded-md"
                    disabled={createNewVersion.isLoading}
                    onClick={() => {
                      const description = (
                        document.getElementById('versionDescription') as HTMLInputElement
                      )?.value;
                      createNewVersion
                        .mutateAsync({
                          path: page.path,
                          content: encode(query.serialize()),
                          description,
                        })
                        .then(async () => {
                          const versions = await editablePageVersions.refetch();
                          const pageVersions = versions.data?.find(v => v.path === path)?.versions;
                          const latestVersion = pageVersions?.[pageVersions.length - 1];
                          setQueryParams({ version: latestVersion, edit: true });
                        });
                    }}
                  >
                    {createNewVersion.isLoading ? 'Saving...' : 'Save New Version'}
                  </button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <div className="grid gap-1">
            <Checkbox
              label={
                <strong>
                  Enable links (you will lose any unsaved <br />
                  changes if you click on a link)
                </strong>
              }
              checked={enableLinks}
              onCheckedChange={value => setEnableLinks(Boolean(value))}
            />
            <Checkbox
              label={<strong>Show layer guidelines</strong>}
              checked={showGuidelines}
              onCheckedChange={value => setShowGuidelines(Boolean(value))}
            />
          </div>
          <Accordion.Root type="multiple" defaultValue={['layers']} className="w-full grid gap-1">
            <Accordion.Item value="versions" className="bg-orange-50 p-1 grid gap-1">
              <Accordion.Header>
                <Accordion.Trigger className="flex gap-2 justify-between w-full">
                  <span>
                    Displayed version:{' '}
                    <strong
                      className={`border px-.5 ${
                        !isProdVersion
                          ? 'bg-custom-green text-black border-custom-green'
                          : 'bg-red-700 text-white border-red-700'
                      }`}
                    >
                      {isProdVersion ? 'PROD' : version}
                    </strong>
                    {hasContentChanged && (
                      <span
                        className={`font-bold text-black px-.5 border ${
                          !isProdVersion ? 'border-custom-green' : 'border-red-700'
                        }`}
                      >
                        edited
                      </span>
                    )}
                  </span>
                  <IconChevronDown />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="grid gap-.5 max-h-[max(30vh,300px)] overflow-auto">
                  {pageVersionData?.versions
                    ?.slice()
                    .reverse()
                    .map(v => {
                      const versionData = pageVersionData?.versionDetails?.[v];
                      const description = pageVersionData?.descriptions?.[v];
                      console.log('V', description);
                      return (
                        <div
                          key={v}
                          className="px-1 py-.25 bg-orange-100 w-full text-left flex flex-col"
                        >
                          <div className="flex items-center gap-1 justify-end max-w-[350px]">
                            <span className="flex items-center gap-1 mr-auto w-full overflow-hidden">
                              <span>v{v}</span>
                              {description && (
                                <span
                                  className="text-000 inline-block text-black px-.5 overflow-hidden text-ellipsis line-clamp-1 text-nowrap flex-shrink"
                                  title={description}
                                >
                                  {description}
                                </span>
                              )}
                              {version === v && !isProdVersion && (
                                <span className="ml-auto text-000 inline-block font-black bg-custom-green text-black px-.5">
                                  CURRENT
                                </span>
                              )}
                              {v === pageVersionData?.activeVersion && (
                                <span className="ml-auto text-000 inline-block font-black bg-red-700 text-white px-.5">
                                  PROD
                                </span>
                              )}
                            </span>
                            <Dialog>
                              <DialogTrigger asChild>
                                <button
                                  className="bg-blue-100 px-.5 py-.25 disabled:opacity-30 rounded-md text-00 inline-block"
                                  disabled={!hasContentChanged && v === version}
                                  title="Load and discard changes"
                                >
                                  <CloudDownloadIcon className="size-2" />
                                </button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>Load Version</DialogTitle>
                                  <DialogDescription>
                                    You have unsaved changes. Are you sure you want to switch
                                    versions? Your changes will be lost.
                                  </DialogDescription>
                                </DialogHeader>
                                <DialogFooter className="flex gap-2 sm:justify-between">
                                  <DialogClose asChild>
                                    <button className="bg-gray-100 px-2 py-1 rounded font-semibold">
                                      Cancel
                                    </button>
                                  </DialogClose>
                                  <DialogClose asChild>
                                    <button
                                      className="bg-red-400 px-2 py-1 rounded font-semibold"
                                      onClick={() => {
                                        setQueryParams({ version: v, edit: true });
                                      }}
                                    >
                                      Load and discard changes
                                    </button>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>

                            <Dialog>
                              <DialogTrigger asChild>
                                <button
                                  className="bg-orange-300 px-.5 py-.25 disabled:opacity-30 rounded-md text-00 inline-block"
                                  disabled={v === pageVersionData?.activeVersion}
                                  onClick={e => e.stopPropagation()}
                                  title="Deploy"
                                >
                                  <RocketIcon className="size-2" />
                                </button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>Deploy Version</DialogTitle>
                                  <DialogDescription>
                                    Are you sure you want to <strong>deploy this version</strong>?
                                  </DialogDescription>
                                </DialogHeader>
                                <DialogFooter className="flex gap-2 sm:justify-between">
                                  <DialogClose asChild>
                                    <button className="bg-gray-100 px-2 py-1 rounded font-semibold">
                                      Cancel
                                    </button>
                                  </DialogClose>
                                  <DialogClose asChild>
                                    <button
                                      className="bg-red-400 px-2 py-1 rounded font-semibold"
                                      onClick={async () => {
                                        await setActiveVersion.mutateAsync({ path, version: v });
                                      }}
                                      disabled={setActiveVersion.isLoading}
                                    >
                                      {setActiveVersion.isLoading ? 'Deploying...' : 'Deploy'}
                                    </button>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                            <Dialog>
                              <DialogTrigger asChild>
                                <button
                                  className="bg-red-400 px-.5 py-.25 disabled:opacity-30 rounded-md text-00 inline-block"
                                  disabled={v === pageVersionData?.activeVersion || v === version}
                                  onClick={e => e.stopPropagation()}
                                  title="Delete"
                                >
                                  <TrashIcon className="size-2" />
                                </button>
                              </DialogTrigger>
                              <DialogContent>
                                <DialogHeader>
                                  <DialogTitle>Delete Version</DialogTitle>
                                  <DialogDescription>
                                    Are you sure you want to <strong>delete this version</strong>?
                                  </DialogDescription>
                                </DialogHeader>
                                <DialogFooter className="flex gap-2 sm:justify-between">
                                  <DialogClose asChild>
                                    <button className="bg-gray-100 px-2 py-1 rounded font-semibold">
                                      Cancel
                                    </button>
                                  </DialogClose>
                                  <DialogClose asChild>
                                    <button
                                      className="bg-red-400 px-2 py-1 rounded font-semibold"
                                      onClick={() => {
                                        deleteVersion.mutateAsync({ path, version: v });
                                      }}
                                      disabled={deleteVersion.isLoading}
                                    >
                                      {deleteVersion.isLoading ? 'Deleting...' : 'Delete'}
                                    </button>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          {versionData?.description && (
                            <div
                              className="text-000 italic px-1 text-gray-600 truncate"
                              title={versionData.description}
                            >
                              {versionData.description}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="components" className="bg-green-50 p-1 grid gap-1">
              <Accordion.Header>
                <Accordion.Trigger className="flex gap-2 justify-between w-full">
                  <span>Components</span>
                  <IconChevronDown />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="bg-green-100 grid gap-.5 p-.5 grid-cols-2">
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={HeroSection}
                          imgSrc="https://cdn.thenold.com/landing-pages/piferi/Fade 100 Pink Satin Crystals_compressed.webp"
                          imgBlurSrc="https://cdn.thenold.com/landing-pages/piferi/Fade 100 Pink Satin Crystals_blur.webp"
                          canvas
                        />
                      )
                    }
                  >
                    Hero Section
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={Button} text="Button" />)
                    }
                  >
                    Button
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref => ref && connectors.create(ref, <Element is={Text}>Text</Element>)}
                  >
                    Text
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={Image}
                          src="https://picsum.photos/800/600"
                          blurSrc="https://picsum.photos/20/15"
                          alt="Example image"
                        />
                      )
                    }
                  >
                    Image
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref => ref && connectors.create(ref, <Element is={Spacer} size="1" />)}
                  >
                    Spacer
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element is={Container} canvas>
                          <Element is={Text}>Container</Element>
                        </Element>
                      )
                    }
                  >
                    Container
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element is={ShowcaseCarousel} canvas>
                          <Element
                            is={ShowcaseCarouselItem}
                            imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          >
                            <Element
                              is={Text}
                              font="syne"
                              size="3"
                              casing="uppercase"
                              color="white"
                            >
                              Showcase Item 1
                            </Element>
                            <Element is={Spacer} size="2" />
                            <Element is={Button} text="Button 1" />
                          </Element>
                          <Element
                            is={ShowcaseCarouselItem}
                            imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          >
                            <Element
                              is={Text}
                              font="syne"
                              size="3"
                              casing="uppercase"
                              color="white"
                            >
                              Showcase Item 2
                            </Element>
                            <Element is={Spacer} size="2" />
                            <Element is={Button} text="Button 2" />
                          </Element>
                          <Element
                            is={ShowcaseCarouselItem}
                            imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                            imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          >
                            <Element
                              is={Text}
                              font="syne"
                              size="3"
                              casing="uppercase"
                              color="white"
                            >
                              Showcase Item 3
                            </Element>
                            <Element is={Spacer} size="2" />
                            <Element is={Button} text="Button 3" />
                          </Element>
                        </Element>
                      )
                    }
                  >
                    Showcase
                    <br />
                    Carousel
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={ShowcaseCarouselItem}
                          imgSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          imgBlurSrc="https://images.unsplash.com/photo-1718471472310-77a63c5fad95?q=80&w=20&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        >
                          <Element is={Text} font="syne" size="3" casing="uppercase" color="white">
                            Showcase Item
                          </Element>
                          <Element is={Spacer} size="2" />
                          <Element is={Button} text="Button 3" />
                        </Element>
                      )
                    }
                  >
                    Showcase
                    <br />
                    Carousel Item
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref => ref && connectors.create(ref, <Element is={ListingsSection} />)}
                  >
                    Listings
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={FollowingSectionWrapper} />)
                    }
                  >
                    🆕 Following
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={MatrixSectionWrapper} />)
                    }
                  >
                    🆕 Matrix
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={CarouselSectionWrapper}
                          title="Carousel Title"
                          subtitle="Carousel Subtitle"
                          collectionId={1}
                          items={[
                            ...new Array(6).fill(null).map((_, index) => ({
                              imageUrl: `https://picsum.photos/600/800?random=${index + 1}`,
                              imageBlurUrl: `https://picsum.photos/30/40?random=${index + 1}`,
                              title: `Item Title ${index + 1}`,
                              searchParams: '',
                            })),
                          ]}
                        />
                      )
                    }
                  >
                    🆕 Carousel
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={LookingForSectionWrapper} />)
                    }
                  >
                    🆕 Looking For
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={InstagramSectionWrapper} />)
                    }
                  >
                    🆕 Instagram
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={FeaturedSectionWrapper} />)
                    }
                  >
                    🆕 Featured in
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={BlackSectionWrapper}
                          canvas
                          sentences={[
                            'Black Sentence 1',
                            'Black Sentence 2',
                            'Black Sentence 3',
                            'Black Sentence 4',
                          ]}
                          ctaText="Black CTA Text"
                          ctaLink={{ name: 'LandingPage', params: {} }}
                        >
                          <Text>Black Title</Text>
                        </Element>
                      )
                    }
                  >
                    🆕 Black
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={SampleSaleSectionWrapper} />)
                    }
                  >
                    🆕 Sample Sale
                  </button>
                  {/* Icons */}
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref => ref && connectors.create(ref, <Element is={IconsSectionWrapper} />)}
                  >
                    🆕 Icons
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={TrendingNowSectionWrapper} />)
                    }
                  >
                    🆕 Trending Now
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={ListingSectionWrapper} />)
                    }
                  >
                    🆕 Listings Section
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={AccordionSection}
                          items={[
                            {
                              label: 'Question 1',
                              content: 'Answer to question 1',
                            },
                            {
                              label: 'Question 2',
                              content: 'Answer to question 2',
                            },
                          ]}
                        />
                      )
                    }
                  >
                    🆕 Accordion Section
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref => ref && connectors.create(ref, <Element is={LeonieHero} canvas />)}
                  >
                    ⏳ Leonie Hero
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref &&
                      connectors.create(
                        ref,
                        <Element
                          is={TimelineSection}
                          heading="Title"
                          items={DEFAULT_TIMELINE_ITEMS}
                        />
                      )
                    }
                  >
                    🆕 Timeline Section
                  </button>
                  <button
                    className="bg-green-200 p-.5"
                    ref={ref =>
                      ref && connectors.create(ref, <Element is={FooterSectionWrapper} />)
                    }
                  >
                    🆕 Footer Section
                  </button>
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="layers" className="bg-yellow-50 p-1 grid gap-1">
              <Accordion.Header>
                <Accordion.Trigger className="flex gap-2 justify-between w-full">
                  <span>Layers</span>
                  <IconChevronDown />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="grid gap-1">
                  <SecondaryButton
                    onClick={async () => {
                      try {
                        const clipboardText = await navigator.clipboard.readText();
                        const pastedData = JSON.parse(clipboardText);

                        if (pastedData.isCraftjsLayer) {
                          const targetNodeId = selected?.id || 'ROOT';

                          // We have an issue where some pages have orphaned nodes.
                          // This is a fix for that, so that we can copy the whole page
                          // and paste it to another (or the same) page and it works.
                          Object.values(pastedData.data).forEach((node: any) => {
                            if (!node.parent && node.id !== 'ROOT') {
                              node.parent = targetNodeId;
                              console.debug('Setting parent to', targetNodeId, 'for', node.id);
                            }
                          });

                          if (pastedData.data) {
                            let newTree;
                            if (pastedData.rootNodeId === 'ROOT') {
                              newTree = pastedData.data;
                            } else {
                              const currentTree = JSON.parse(query.serialize());
                              currentTree[targetNodeId].nodes.push(pastedData.rootNodeId);
                              newTree = {
                                ...currentTree,
                                ...pastedData.data,
                              };
                              newTree[pastedData.rootNodeId].parent = targetNodeId;
                            }

                            actions.deserialize(JSON.stringify(newTree));
                            actions.selectNode(pastedData.rootNodeId);
                          }

                          toast.success('Layer pasted successfully');
                        } else {
                          toast.error('Invalid layer data. Please paste a valid layer.');
                        }
                      } catch (error) {
                        console.error(error);
                        toast.error('Failed to paste layer. Please paste a valid layer.');
                      }
                    }}
                    className="text-sm"
                  >
                    <span>
                      paste component
                      <br />
                      <span className="normal-case text-000">
                        (select target paste layer first)
                      </span>
                    </span>
                  </SecondaryButton>
                  <Layers expandRootOnLoad renderLayer={Layer} />
                </div>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
          {selected?.settings && (
            <div className="bg-yellow-100 p-2">{React.createElement(selected.settings)}</div>
          )}
        </div>
      )}
    </div>
  );
};

function useReloadOnEditorUpdate() {
  useEditor(state => ({
    serializedState: state.nodes, // Change tracking
  }));
}
