import { Frame } from '@craftjs/core';
import { Element } from '@craftjs/core';
import { decode } from 'js-base64';
import { toast } from 'react-hot-toast';
import { useLocation, useParams } from 'react-router-dom';

import {
  LayoutSingleColumn,
  MobileBottomNavigation,
  NamedLink,
  NamedRedirect,
  Page,
  PrimaryButton,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { CustomEditor } from './CustomEditor';
import {
  useCreateEditablePage,
  useDeletePage,
  useEditablePagesQueryParams,
  useGetEditablePage,
  useGetEditablePageVersions,
  useRenamePage,
} from './EditablePages.hooks';
import { useIsNoldUser } from './EditablePages.hooks';
import css from './EditablePages.module.css';
import { Toolbox, useEnableLinksState } from './Toolbox';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/_chadcn/Dialog';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { cn } from 'util/cn';

const EditablePages = () => {
  const { pageId = '' } = useParams<{ pageId: string }>();
  const { isNoldEmail, isLoading } = useIsNoldUser();

  if (isLoading) {
    return null;
  }

  if (pageId === 'list' && isNoldEmail) {
    return <ListEditablePages />;
  }

  return <EditPage pageId={pageId} />;
};

const EditPage = ({ pageId }: { pageId: string }) => {
  const scrollingDisabled = useIsScrollingDisabled();
  const location = useLocation();
  const { isNoldEmail, isLoading: isLoadingUser } = useIsNoldUser();
  const isEditing = !!location.search?.includes('edit=true') && isNoldEmail;

  const [{ version }] = useEditablePagesQueryParams();
  const { data: page, isLoading, isError, error } = useGetEditablePage(pageId, version);

  const [enableLinks] = useEnableLinksState();

  if (!pageId) {
    return null;
  }

  if (isLoading || isLoadingUser) {
    return null;
  }

  if (isError && !isEditing) {
    return <NamedRedirect name="LandingPage" />;
  }

  if (isError && isEditing) {
    return (
      <div className="text-red-500 w-screen h-screen p-2 grid place-content-center text-center">
        Error: {JSON.stringify(error)}
      </div>
    );
  }

  console.log(isEditing, page);

  if (!page) {
    return <NamedRedirect name="LandingPage" />;
  }

  return (
    <Page title="THE NOLD" scrollingDisabled={scrollingDisabled}>
      <CustomEditor isEditing={isEditing}>
        <div className="grid grid-cols-[1fr_auto] w-screen h-screen">
          <LayoutSingleColumn
            topbar={<TopbarContainer />}
            footer={<MobileBottomNavigation />}
            footerClassName="w-full overflow-x-hidden"
            className="w-full"
            mainColumnClassName={cn(
              { [css.linksDisabled]: isEditing && !enableLinks },
              'w-full overflow-x-hidden'
            )}
          >
            <Frame json={isEditing ? undefined : decode(page?.content ?? '')}>
              <Element
                canvas
                is="div"
                className="craftjs-renderer min-h-px w-full overflow-x-hidden"
                id="root"
              ></Element>
            </Frame>
          </LayoutSingleColumn>
          {isEditing && <Toolbox page={page} />}
        </div>
      </CustomEditor>
    </Page>
  );
};

const ListEditablePages = () => {
  const scrollingDisabled = useIsScrollingDisabled();
  const { data: editablePages } = useGetEditablePageVersions();
  const createEditablePage = useCreateEditablePage();
  const deleteEditablePage = useDeletePage();
  const renamePage = useRenamePage();

  return (
    <Page title="THE NOLD | Editable Pages" scrollingDisabled={scrollingDisabled}>
      <div className="grid grid-cols-1 gap-2">
        <h2 className="p-2 font-bold font-syne text-3">THE NOLD | Editable Pages</h2>
        <form
          className="flex flex-col gap-2 p-2 items-start bg-gray-100"
          onSubmit={e => {
            e.preventDefault();
            const path = e.currentTarget.pageRoute.value;
            if (path) {
              createEditablePage.mutate(
                { path },
                {
                  onSuccess: () => {
                    e?.currentTarget?.reset();
                    toast.success('Page created successfully');
                  },
                }
              );
            }
          }}
        >
          <h1 className="font-bold uppercase font-syne text-2">Create new page</h1>
          <div className="flex gap-2">
            <label>
              Page route (path)
              <input className="p-1 bg-white" placeholder="page-route" id="pageRoute" />
            </label>
          </div>
          <PrimaryButton type="submit" disabled={createEditablePage.isLoading}>
            {createEditablePage.isLoading ? 'Creating...' : 'Create'}
          </PrimaryButton>
        </form>
        <div className="grid grid-cols-1 gap-1 p-2">
          <h1 className="font-bold uppercase font-syne text-2 mb-1">Pages list</h1>
          {editablePages?.map(page => (
            <div className="flex gap-1">
              <NamedLink
                key={page.path}
                name="EditablePages"
                params={{ pageId: page.path }}
                to={{ search: `?edit=true` }}
                className="flex-1 p-2 bg-gray-100 hover:bg-gray-200 transition-colors font-bold"
              >
                {page.path} (active version: {page.activeVersion}, latest version:{' '}
                {page.versions?.[0]})
              </NamedLink>
              {page.path !== 'home' && page.path !== 'nold-bg' && (
                <>
                  <Dialog>
                    <DialogTrigger asChild>
                      <button className="p-2 bg-yellow-100 hover:bg-yellow-200 transition-colors font-bold">
                        Rename
                      </button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Rename Page - {page.path}</DialogTitle>
                        <DialogDescription className="mt-2">
                          Enter a new path for the page <b className="text-black">{page.path}</b>
                        </DialogDescription>
                      </DialogHeader>
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          const newPath = e.currentTarget.newPath.value;
                          if (newPath) {
                            renamePage.mutate(
                              { path: page.path, newPath },
                              {
                                onSuccess: () => {
                                  e?.currentTarget?.reset();
                                },
                              }
                            );
                          }
                        }}
                      >
                        <div className="py-4">
                          <input
                            id="newPath"
                            name="newPath"
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="New path"
                            defaultValue={page.path}
                          />
                        </div>
                        <DialogFooter className="sm:justify-between">
                          <DialogClose asChild>
                            <button
                              type="button"
                              className="p-2 bg-gray-100 hover:bg-gray-200 transition-colors font-bold"
                            >
                              Cancel
                            </button>
                          </DialogClose>
                          <DialogClose asChild>
                            <button
                              type="submit"
                              className="p-2 bg-yellow-500 text-white hover:bg-yellow-600 transition-colors font-bold"
                            >
                              Rename
                            </button>
                          </DialogClose>
                        </DialogFooter>
                      </form>
                    </DialogContent>
                  </Dialog>
                  <Dialog>
                    <DialogTrigger asChild>
                      <button className="p-2 bg-red-100 hover:bg-red-200 transition-colors font-bold">
                        Delete
                      </button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Delete Page - {page.path}</DialogTitle>
                        <DialogDescription className="mt-2">
                          Are you sure you want to delete the page{' '}
                          <b className="text-black">{page.path}</b>? This action cannot be undone.
                          <br />
                          <br />
                          This will delete all versions of the page.
                        </DialogDescription>
                      </DialogHeader>
                      <DialogFooter className="sm:justify-between">
                        <DialogClose asChild>
                          <button className="p-2 bg-gray-100 hover:bg-gray-200 transition-colors font-bold">
                            Cancel
                          </button>
                        </DialogClose>
                        <DialogClose asChild>
                          <button
                            className="p-2 bg-red-500 text-white hover:bg-red-600 transition-colors font-bold"
                            onClick={() => deleteEditablePage.mutate({ path: page.path })}
                          >
                            Delete
                          </button>
                        </DialogClose>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default EditablePages;
